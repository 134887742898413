/* eslint-disable @typescript-eslint/no-explicit-any */
import { MIN_CALENDAR_API_VERSION } from '../constants/defaults';
import { setActiveDavidSiteId, setIsSmartClientAvailable } from '../redux-modules/app/actions';
import { handleLoadIntercom } from '../redux-modules/intercom/actions';
import { handleLoadData, setServer } from '../redux-modules/smart-client/actions';
import { fetchBadges, setTapps } from '../redux-modules/tapps/actions';
import { TextStrings } from '../redux-modules/text-strings/actions';
import { getCookie } from './helper';
import parseUserData from './parseUserData';
import setInitialTapps from './setInitialTapps';
import smartClientServerAvailable from './smart-client/smartClientServerAvailable';
import { SmartClientServer } from '../types/smartClient';

type Props = {
    hasUserLoggedIn: boolean,
    newTappId: number,
    textStrings: TextStrings,
    dispatch: (action: any) => any,
    isMobile: boolean,
    activeDavidSiteId?: string,
    params: {
        [key: string]: string;
    },
};

const setTappsHelper = async ({
    hasUserLoggedIn,
    newTappId,
    textStrings,
    dispatch,
    isMobile,
    activeDavidSiteId,
    params,
}: Props) => {
    let updatedTapps;
    if (hasUserLoggedIn) {
        const { davidWebAccesses } = chaynsInfo;
        const isSCAvailable = smartClientServerAvailable(davidWebAccesses as unknown as SmartClientServer[]);
        if (isSCAvailable) {
            const availableServers = davidWebAccesses?.filter((s) => s.apiVersion > '1.0.186');
            const isCalendarAvailable = !!availableServers?.find((s) => s.apiVersion > MIN_CALENDAR_API_VERSION);
            updatedTapps = setInitialTapps(chaynsInfo.Tapps, [], true, textStrings, isCalendarAvailable);
            dispatch(setIsSmartClientAvailable(true));
            dispatch(setServer(availableServers as unknown as SmartClientServer[]));
            const lastDavidSiteId = activeDavidSiteId || getCookie('davidSiteId');
            const activeServer = availableServers?.length > 0 && (availableServers.find((s) => s.davidSiteId === lastDavidSiteId) || availableServers[0]);
            dispatch(handleLoadData(activeServer as unknown as SmartClientServer));
            dispatch(handleLoadIntercom({take: 1000}));
            dispatch(setActiveDavidSiteId(activeServer.davidSiteId));
            dispatch(fetchBadges({
                user: parseUserData(),
                tapps: updatedTapps,
            }));
        } else {
            updatedTapps = setInitialTapps(chaynsInfo.Tapps, [], false, textStrings, false);
        }
    } else {
        updatedTapps = setInitialTapps(chaynsInfo.Tapps, [], false, textStrings, false);
    }

    if (params && newTappId) {
        const paramArray = [];
        Object.keys(params).forEach((key) => {
            const value = params[key];
            paramArray.push(`${key}=${value}`);
        });
        const tappIndex = updatedTapps.findIndex((tapp: { id: number }) => tapp.id === newTappId);
        if (tappIndex > -1) {
            updatedTapps[tappIndex] = { ...updatedTapps[tappIndex], params: paramArray };
        }
    }
    dispatch(setTapps(updatedTapps));
};

export default setTappsHelper;
